@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.11') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Regular.woff?v=3.11') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('https://rsms.me/inter/font-files/Inter-Bold.woff2?v=3.11') format('woff2'),
    url('https://rsms.me/inter/font-files/Inter-Bold.woff?v=3.11') format('woff');
  font-display: swap;
}

body {
  font-family: Inter, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* improve firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

* {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible:not(.outline-none):not(.focus\:outline-none) {
  outline: 2px dotted currentColor;
}

input:not([type='radio']):not([type='checkbox']) {
  @apply h-10;
}
